import { type JSX } from 'solid-js'

/*

      THIS FILE IS NOT WORTH UPDATING. SOMEDAY IT WILL BE REPLACED WITH WHATEVER'S USED IN APP

      highTODO

*/

export default function Edit(): JSX.Element {
	return (
		<main>
			<h1>Edit Template</h1>
		</main>
	)
}
